export const environmentBase = {
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	idToken: 'TokenID',
	su: 'su',
	expires_in: 'expires_in',
	sessionId: 'SessionID',
	refreshToken: 'TokenRefresh',
	maestroSyncToken: 'TokenMaestroSync',
	username: 'username',
	notifications: 'notifications',

	COD_SISTEMA: 'SCA',

	URL_LOGIN: 'https://dev-login.sintad.net.pe/#/auth/login',

	HOST_SECURITY: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-security/api/security',
	HOST_CONFIG: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-config/api',
	HOST_MAESTROS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-maestros/api',

	HOST_CARGA: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-carga/api',
	HOST_INFO_OPER: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-informacion-operativa/api',
	HOST_DOC_OPER: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-documentos-operativos/api',
	HOST_EMISION_ELECTRONICA: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-emision-electronica/api',

	HOST_VENTAS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-ventas/api',
	HOST_COMPRAS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-compras/api',
	HOST_BANCOS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-bancos/api',

	HOST_MAESTROS_SYNC: 'https://api-cluster-sistemas-internos.sumax.pe/dev/sumax-erp-backend-maestros-sync/api',
	URL_MAESTROS: 'https://dev-maestros.sintad.net.pe/#/maestros',
	// URL para abrir Iframe DMS
	URL_DMS: 'https://dev-dms.sintad.net.pe', //http://localhost:4200',
	URL_DOCS_OPS: 'https://dev-documentos-operativos.sintad.net.pe', //'http://localhost:4300',
	CONFIG_IMAGE: {
		HOST: 'https://sintad.net.pe/sumax_dms',
		IMAGE: {
			DEFAULT: './assets/img/upload/picture_default.svg',
			NOT: './assets/img/upload/picture_default.svg',
		},
	},

	CONFIG_FILE: {
		HOST: 'https://data-sumax-dev.s3.us-east-2.amazonaws.com/mod-sca/documentos/',
		FILE: {
			DEFAULT: './assets/img/upload/picture_default.svg',
			NOT: './assets/img/upload/picture_default.svg',
		},
	},

	searchTbEntidadDto: {
		idTipoDocumento: 'idTipoDocumento',
		nroDocumento: 'nroDocumento',
	},

	HOST_ROOT: '.sintad.net.pe',

	URL_DOC_OPER: 'https://dev-documentos-operativos.sintad.net.pe/',
};

export function mergeEnviroments<T>(environmentBase: T, newEnviroment: Partial<T>): T {
	return {
		...environmentBase,
		...newEnviroment,
	};
}
