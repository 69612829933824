<kt-portlet>
	<kt-portlet-header [icon]="'fas fa-tags fa-2x'">
		<ng-container ktPortletTitle class="pl-0">
			<h3 class="kt-portlet__head-title py-1 mr-1">title</h3>
		</ng-container>

		<ng-container KtPortletSticky>
			Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dignissimos consectetur et, laboriosam ut itaque id, deserunt sequi exercitationem est aut odit, minus libero ipsum repudiandae
			veritatis at ex ullam illo.
		</ng-container>
	</kt-portlet-header>

	<kt-portlet-body [modalScroll]="true">
		<mat-form-field class="w-100" appearance="outline">
			<mat-label>Pagina</mat-label>
			<input [formControl]="formControl" matInput placeholder="Buscar paginas" /><!---->
		</mat-form-field>

		<mat-selection-list [multiple]="false">
			<mat-list-option (keyup.enter)="selectedChange(menu)" (click)="selectedChange(menu)" *ngFor="let menu of menus$ | async | filter : formControl.value" [value]="menu">
				{{ menu.title }}
			</mat-list-option>
		</mat-selection-list>
	</kt-portlet-body>

	<kt-portlet-footer></kt-portlet-footer>
</kt-portlet>
