import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
	providedIn: 'root',
})
export class CookiesService {
	constructor(private readonly _cookieService: CookieService) {}

	setItem(key: string, value: string): void {
		// this._cookieService.set(key, value);
		const honstName = environment.production ? '.sintad.net.pe' : 'localhost';
		this._cookieService.set(key, value, undefined, '/', honstName, true);
	}

	getItem(key: string): string {
		return this._cookieService.get(key);
	}

	removeItem(key: string): void {
		// this._cookieService.delete(key);
		this._cookieService.delete(key, '/', environment.HOST_ROOT, true);
	}

	checkItem(key: string): boolean {
		return this._cookieService.check(key);
	}
}
