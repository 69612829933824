import { Injectable } from '@angular/core';
import { AppState } from '@core/store';
import { deshabilitarComboOficinas, habilitarComboOficinas } from '@core/store/actions/oficinas.actions';
import { SciFormularioAccionListado } from '@core/store/models/SciFormularioAccionListado';
import { User } from '@core/store/models/user.model';
import { currentAccesos } from '@core/store/selectors/accesos.selectors';
import { currentUser } from '@core/store/selectors/auth.selectors';
import { currentMenu } from '@core/store/selectors/menu.selectors';
import { oficinaSelected } from '@core/store/selectors/oficinas.selectors';
import { getCurrentRouteState } from '@core/store/selectors/router.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators';
import { ConfigButtonAgGrid, EnumButtonType } from 'sumax-erp-component';
import { SciOficina } from '~models/config/interface/SciOficina';
import { KEY_FORM } from '~models/Util/EnumForm';
import { EnumPermissionDefault } from '~models/Util/EnumPermission';

// eslint-disable-next-line @typescript-eslint/naming-convention
function _defaultActions(permissions: string[]): ConfigButtonAgGrid[] {
	return [
		{
			actionCode: EnumButtonType.TYPE_EDIT,
			tooltipOption: {
				label: 'Modificar',
				classList: 'bg-warning',
			},
			iconOption: {
				classList: 'text-warning',
				icon: 'fa fa-pen',
			},
			hide: (): boolean => !permissions.includes(EnumPermissionDefault.Modificar),
		},
		{
			actionCode: EnumButtonType.TYPE_DELETE,
			tooltipOption: {
				label: 'Eliminar',
				classList: 'bg-danger',
			},
			iconOption: {
				classList: 'text-danger',
				icon: 'fa fa-trash',
			},
			hide: (): boolean => !permissions.includes(EnumPermissionDefault.Eliminar),
		},
	];
}

export const defaultActions = _defaultActions([EnumPermissionDefault.Modificar, EnumPermissionDefault.Eliminar]);

@Injectable({ providedIn: 'root' })
export class StoreService {
	currentUser$ = this._store.select(currentUser).pipe(
		filter((user) => user != null),
		map((user) => user as Required<User>)
	);

	currentUserId$ = this.currentUser$.pipe(map((user) => user.id));

	currentOffice$ = this._store.select(oficinaSelected).pipe(
		filter((office) => office != null),
		map((office) => office as Required<SciOficina>)
	);

	currentOfficeId$ = this.currentOffice$.pipe(
		distinctUntilChanged(),
		map(({ idOficina }) => idOficina)
	);

	currentMenu$ = this._store.select(currentMenu);

	currentAccces$ = this._store.select(currentAccesos).pipe(
		filter((formularioAcciones) => formularioAcciones.length > 0),
		withLatestFrom(this._store.select(getCurrentRouteState)),
		map(([accessForm, { data }]) => {
			const form = data[KEY_FORM] as string;

			if (form == null) return null;

			return accessForm.filter(({ codigo }) => codigo == form)[0];
		}),
		filter((access) => access != null),
		map((access) => access as Required<SciFormularioAccionListado>)
		// distinctUntilChanged((x, y) => x.codigo == y.codigo)
	);

	currentActions$: Observable<[string[], ConfigButtonAgGrid[]]> = this.currentAccces$.pipe(
		map(({ acciones }) => acciones.map(({ codigo }) => String(codigo))),
		// distinctUntilChanged((x, y) => y.join('') == y.join('')),
		map((actions) => [actions, _defaultActions(actions)])
	);

	constructor(private readonly _store: Store<AppState>) {}

	disabledOfficeCombo(): void {
		this._store.dispatch(deshabilitarComboOficinas());
	}

	enabledOfficeCombo(): void {
		this._store.dispatch(habilitarComboOficinas());
	}
}
