// eslint-disable-next-line @typescript-eslint/naming-convention
export const KEY_FORM = 'form';

export const enum EnumForm {
	Routing = 'SCA-ROUTING',
	SolicitudTransbordo = 'SCA-SOLICITUD-TRANSBORDO',
	SeguimientoOperativo = 'SCA-SEGUIMIENTO-OPERATIVO',
	ProvisionAdministrativa = 'SCA-PROVISION-ADMINISTRATIVA',
	Contrato = 'SCA-CONTRATO',
	Nave = 'SCA-NAVE',
	CondicionServicio = 'SCA-CONDICION',
	Documento = 'SCA-DOCUMENTO',
	ProgramaNave = 'SCA-PROGRAMA-NAVE',
	ControlContenedor = 'SCA-CONTROL-CONTENEDOR',
	ConfiguracionEmisionDocumento = 'SCA-CONFIG-SEGUIMIENTO-OPERATIVO',
	EmisionDocumento = 'SCA-EMISION-DOCUMENTO',
	Plantilla = 'SCA-PLANTILLA',
	SeguimientoOperativoRo = 'SCA-SEGUIMIENTO-RO',
}
